<template>
  <div class="quill">
    <div
      class="quill-editor eagle-a11y-uncut"
      :content="testcontent"
      :disabled="disabled"
      @change="onEditorChange($event)"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
      v-quill:myQuillEditor="editorOption"
    ></div>
  </div>
</template>

<script>
export default {
  name: "editor",
  props: ["maxlength", "placeholder", "testcontent", "disabled"],
  components: {},
  data() {
    return {
      content: "", //富文本中数据
      editorOption: {
        // 富文本中的一些属性
        modules: {
          toolbar: [[{ list: "ordered" }, { list: "bullet" }]]
        },
        placeholder: this.placeholder
        // maxlength:'150文字',
      }
    };
  },
  methods: {
    onEditorChange({ editor, html, text }) {
      //内容改变事件
      // console.log( html)
      this.content = html;
      this.$emit("html", this.content);
    },
    onEditorBlur(editor) {
      //失去焦点事件
      // console.log('editor blur!', editor)
    },
    onEditorFocus(editor) {
      //获得焦点事件
      // console.log('editor focus!', editor)
    },
    onEditorReady(editor) {
      //准备编辑器
      // console.log( editor)
    },
    save() {
      //提交
      //....
    }
  }
};
</script>
<style scoped lang="less">
.quill {
  height: 220px;
  margin-bottom: 30px;
}
</style>
