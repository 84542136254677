<template>
  <div class="world backgroundWhite">
    <p class="sub_title">
      您还想学什么课程呢？填写反馈，我们会尽量满足您的需求。
    </p>
    <div class="content">
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        class="form"
        :rules="rules"
      >
        <el-form-item label="课程类型" prop="needsType">
          <el-input
            v-model="form.needsType"
            placeholder="请填写课程类型，例如心理健康类"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程内容">
          <Editor
            placeholder="请详细描述想要学习的课程内容"
            @html="text"
            class="width100"
            v-model="form.needsContent"
          ></Editor>
        </el-form-item>
        <el-form-item class="buttons text_C">
          <el-button type="primary" class="submit" @click="save('form')"
            >提交</el-button
          >
          <el-button class="cansel" @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Editor from "components/public/editor";
export default {
  name: "demeandInput",

  components: { Editor },
  data() {
    return {
      userType: "",
      form: {
        expectTime: "",
        needsContent: "",
        needsType: "",
        remark: "GP",
      },
      rules: {
        needsType: [
          {
            required: true,
            message: "请输入课程类型",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    text(val) {
      this.form.needsContent = val;
    },
    save(form) {
      if (!this.form.needsType) {
        this.$message.error("请输入课程类型");
        return;
      }
      if (!this.form.needsContent) {
        this.$message.error("请输入课程内容");
        return;
      }
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let res = await this.$api.addNeed(this.form);
          if (res.data.success) {
            this.$message.success("需求添加成功");
            this.close(0);
            this.$parent.$parent.needsList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
  },
  created() {
    if (this.$store.state.login) {
      this.userType = this.$store.state.login.userInfo.userType;
    }
  },
  mounted() {
    this.form.expectTime = new Date().toLocaleDateString();
    console.log(this.form.expectTime, 963963);
  },
};
</script>

<style scoped lang="less">
.child_content .title h1 {
  transform: translateY(-10px);
}
.sub_title {
  font-size: 18px;
  font-weight: 600;
  color: #00120a;
  line-height: 25px;
  text-align: center;
  margin-bottom: 36px;
}
.buttons {
  text-align: center;
  button {
    width: 96px;
    height: 42px;
    border-radius: 6px;
  }
  .submit {
    margin-right: 96px;
  }
  .cansel {
  }
}
</style>
