    <template>
  <div class="job_recommend_wrap">
    <div class="job_recommend">
      <div class="banner">
        <img
          src="~/static/activity/jobFair-country/banner.png"
          alt=""
          title="高校残疾人毕业生就业就业帮扶"
        />
      </div>
      <div class="content">
        <div class="crumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/activity/jobFair'}">活动首页</el-breadcrumb-item>
            <el-breadcrumb-item>就业指导</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <img src="{~/static/activity/jobFair-country/title6.png}" alt="" />
        <div class="expert">
          <h2>专家答疑</h2>
          <ul>
            <li v-for="(item, index) in expertList.records" :key="index">
              <img :src="item.headSrc" alt="" />
              <div class="text">
                <div class="top">
                  <p class="name">{{ item.name }}</p>
                  <button @click="open()">我要咨询</button>
                </div>
                <span>
                  {{ item.content }}
                </span>
              </div>
            </li>
          </ul>
          <el-pagination
            background
            layout="prev, pager, next,total,jumper"
            :total="expertList.total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <div class="problem">
          <h2>常见问题</h2>
          <ul>
            <li
              v-for="(item, indexs) in problemList"
              :key="indexs"
              @click="goRouter('/asklib/askDetail', item.id)"
            >
              <h3>{{ item.askTitle }}</h3>
              <p class="line_clamp1">{{ item.content }}</p>
            </li>
          </ul>
          <p class="more">
            <span @click="goRouter('/asklib')">了解更多</span>
          </p>
        </div>
      </div>
      <div class="dialog">
        <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="324px"
          :before-close="handleClose"
        >
          <img src="~/static/zhibo.png" alt="" />
          <p class="p1">就业疑惑，听听专家怎么说</p>
          <p class="p2">请您添加企业微信<br />并备注：加入就业资讯1群</p>
        </el-dialog>
      </div>
      <el-dialog
        title="需求反馈"
        :visible.sync="dialogVisibles"
        width="636px"
        :before-close="handleClose"
      >
        <needs></needs>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Needs from "./components/Needs.vue";

export default {
  components: { Needs },
  name: "index",
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      expertList: [],
      problemList: [],
      page: {
        pageNum: 0,
        pageSize: 10,
        topicId: "690deeff008f0b069f2846e641699d97",
      },
      expertPage:{
        pageNum:1,
        pageSize:10,

      }
    };
  },
  methods: {
    getExpert() {
      this.$axios
        .get("/api/app-jycy-activity/getGuopinExpert",this.expertPage)
        .then((res) => {
          this.expertList = res.data.data;
        });
    },
    getProblem() {
      this.$api.askListApi(this.page).then((res) => {
        this.problemList = res.data.data.records;
      });
    },
    open() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCurrentChange(val) {
      console.log("当前" + val + "页");
    },
    // 控制需求反馈组件关闭
    handleClose() {
      this.dialogVisible = false;
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href,"_blank")
    },
  },
  created() {
    this.getExpert();
    this.getProblem();
  },
};
</script>

<style scoped lang="less">
.job_recommend_wrap {
  background: #fffaf3;
  box-sizing: border-box;
  padding-bottom: 48px;
  .job_recommend {
    .banner {
      img {
        width: 100%;
      }
    }
    .content {
      width: 1200px;
      margin: 0 auto;
      .crumb {
        height: 22px;
        margin: 36px 0 48px 0;
      }
      > img {
      }
      h2 {
        font-size: 26px;
        font-weight: 600;
        color: #001108;
        line-height: 37px;
        letter-spacing: 1px;
        position: relative;
        text-align: center;
        margin: 38px 0 34px 0;
        &::after {
          width: 36px;
          height: 4px;
          background: #d71216;
          border-radius: 3px;
          position: absolute;
          bottom: -6px;
          right: 50%;
          transform: translateX(50%);
          content: "";
        }
      }
      .expert {
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            padding: 18px;
            width: 588px;
            height: 150px;
            background: rgba(255, 255, 255, 0.5);
            border: 2px solid #ffffff;
            display: flex;
            margin-bottom: 24px;
            &:hover {
              border: 2px solid #fad49e;
            }
            &:nth-child(2n) {
              margin-left: 24px;
            }
            > img {
              width: 114px;
              height: 114px;
              margin-right: 18px;
              box-shadow: 0px 20px 15px 0px rgba(117, 77, 20, 0.04);
              border-radius: 50%;
            }
            .text {
              flex: 1;
              .top {
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 18px;
                  font-weight: 600;
                  color: #00120a;
                  line-height: 25px;
                  letter-spacing: 1px;
                }
                button {
                  width: 78px;
                  height: 33px;
                  background: #d71216;
                  color: #fbd9a8;
                  &:hover {
                    cursor: pointer;
                  }
                }
              }
              span {
                color: #66716c;
                line-height: 24px;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
      .problem {
        h2 {
          margin: 72px 0 46px 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          width: 1200px;
          // height: 498px;
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid #ffffff;
          li {
            width: 549px;
            padding: 18px 0 18px 18px;
            position: relative;
            &:nth-child(2n) {
              margin-left: 25px;
            }
            &::after {
              width: 552px;
              height: 1px;
              color: red;
              background: rgba(0, 18, 10, 0.05);
              position: absolute;
              content: "";
              bottom: 0px;
              right: -18px;
            }
            h3 {
              font-size: 18px;
              font-weight: 600;
              line-height: 25px;
              color: #000f06;
              cursor: pointer;
              margin-bottom: 12px;
              &:hover {
                color: #d71216;
                // border-bottom: 1px solid #d71216 ;
              }
            }
            p {
              color: #66716c;
              line-height: 22px;
              letter-spacing: 0.67px;
            }
          }
        }
        .more {
          width: 1200px;
          height: 54px;
          background: rgba(255, 255, 255, 0.5);
          margin-top: 24px;
          text-align: center;
          cursor: pointer;
          padding-top: 16px;
          &:hover {
            span {
              color: #d71216;
            }
          }
          span {
            color: #66716c;
            line-height: 22px;
          }
        }
      }
    }
  }
}
.dialog {
  text-align: center;
  img {
    width: 180px;
    height: 180px;
    margin-bottom: 24px;
  }
  .p1 {
    font-size: 18px;
    font-weight: 600;
    color: #001108;
    line-height: 25px;
    margin-bottom: 6px;
  }
  .p2 {
    color: #66716c;
    width: 191px;
    display: inline-block;
    line-height: 22px;
  }
}
</style>